import { ArrayUtils, NumberUtils } from "fwork-jsts-common/src"
import { ImageComponent } from "fwork.react.apptemplate"
import { ImageOrInitLettersComponent } from 'fwork.react.apptemplate/src/components/image'
import React from "react"
import { FaTrophy } from "react-icons/fa"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { IPrizeDraw, ITicket } from "rifa.lib.common.ts/models"
import { defaultTheme, RoutesNames } from "../../../common/globals"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { IPrizeDrawsState } from "../../../redux/reducers/prizeDraws.slice"
import { RootState } from "../../../redux/store"
import { TicketWinnerProfileImageDiv } from "./index.styled"
import { ISettingsState } from "../../../redux/reducers/settings.slice"

export const TicketWinnerComponent = (props: {
  hidePrizeDrawTitle?: boolean,
  ticket: ITicket,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
}) => {
  const navigate = useNavigate()
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)

  const onPrizeDrawCardClick = (prizeDraw: IPrizeDraw) => {
    navigate(RoutesNames.prizeDraw.replace(':id', prizeDraw._id))
  }

  const prizedrawsState: IPrizeDrawsState = useSelector((state: RootState) => state.prizeDrawsState)
  const numbersFromMainAward = ArrayUtils.intersection(props.ticket.prizeDraw?.drawnNumbers || [], props.ticket.numbers || [])?.toString()

  return <>
    {prizedrawsState.payload?.length ?
      <div className="TicketWinnerComponent"
        onClick={() => {
          if (props.ticket.prizeDraw) {
            onPrizeDrawCardClick(props.ticket.prizeDraw)
          }
        }}
        style={{
          display: 'flex',
          padding: 10,
          borderRadius: 5,
          boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 3px',
          background: numbersFromMainAward.length ? settingsState?.payload?.[0]?.theme?.primaryColor ?? defaultTheme.primaryColor : 'white',
          color: numbersFromMainAward.length ? settingsState?.payload?.[0]?.theme?.primaryTextColor : undefined,
          cursor: 'pointer',
          overflow: 'auto',
          ...props.props?.style
        }}>

        <div style={{
          width: '30%',
          position: 'relative',
          minWidth: 120
        }}>
          <ImageComponent style={{
            borderRadius: 5,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            boxSizing: 'border-box',
            opacity: .4,
          }}
            src={`${dotEnvProductionState.payload?.APIURL}/img/${props.ticket.prizeDraw?.imgUrl}`}
          />
          <TicketWinnerProfileImageDiv>
            <ImageOrInitLettersComponent
              src={`${dotEnvProductionState.payload?.APIURL}/img/${props.ticket.customer?.imgProfileUrl}`}
              initLettersText={props.ticket.customer?.name}
              onlyFirstAndLastWord={true}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '100%',
                border: '2px solid white',
                background: props.ticket.customer?.style?.color,
                color: 'white',
                fontSize: 25,
              }}
            />
          </TicketWinnerProfileImageDiv>
        </div>

        <div style={{
          width: '70%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexGrow: 1,
          justifyContent: 'space-between',
          textAlign: 'center',
        }}>
          {!props.hidePrizeDrawTitle ? <p style={{
            marginBlock: 2,
            marginLeft: 10,
          }}><strong>{props.ticket.prizeDraw?.title.substring(0, 50)}{(props.ticket.prizeDraw?.title.length ?? 0) > 50 ? '...' : ''}</strong></p> : <></>}

          <FaTrophy size={32} />
          <p style={{
            marginBlock: 2,
            marginLeft: 10,
          }}>{props.ticket.customer?.name}</p>

          {numbersFromMainAward.length ? <p style={{
            marginBlock: 2,
            marginLeft: 10,
          }}>
            {`Cota${numbersFromMainAward.length > 1 ? 's' : ''} pelo prêmio principal: ${numbersFromMainAward.toString()}`}
          </p> : <></>}

          {props.ticket.awardedNumbers?.length ? props.ticket.awardedNumbers.map(an => <p style={{
            display: 'flex',
            flexDirection: 'column',
            marginBlock: 2,
            marginLeft: 10,
            borderColor: `${numbersFromMainAward.length ? settingsState?.payload?.[0]?.theme?.primaryTextColor : defaultTheme.primaryColor} !important`,
            border: `2px solid`,
            borderRadius: '5px',
            color: numbersFromMainAward.length ? settingsState?.payload?.[0]?.theme?.primaryTextColor : defaultTheme.primaryColor,
            padding: '5px 10px',
          }}>
            <label>{`Cota premiada ${an.number.toString()}`}</label>
            <label style={{ display: 'inline-block', fontWeight: 'bold', }}>{`R$ ${NumberUtils.getNumberStr(an.awardValue)}`} 🔥</label>
          </p>) : <></>}
        </div>
      </div> : <></>}
  </>
}