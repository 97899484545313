export enum HomeTypes {
  settings,
  users,
  user,
  prizeDraws,
  prizeDraw,
  prizeDrawTickets,
  prizeDrawsMovement,
  prizeDrawRanking,
  ticketsToRefund,
}