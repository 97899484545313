import { StyleSheet } from "@react-pdf/renderer";

export const stylesPdfDocument = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#F0F0F0',
    width: '100%',
  },
  section: {
    padding: 5,
    flexGrow: 1,
  },
  headerText: {
    fontSize: 8,
    color: '#FFF',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  title: {
    fontSize: 12,
    marginBottom: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#333',
    padding: '10px 0px 10px 0px',
    textTransform: 'uppercase',
  },
  table: {
    width: '100%',
    borderStyle: 'solid',
    borderColor: '#333',
    backgroundColor: '#333',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCellHeader: {
    flex: 1,
    padding: "5px 0px 5px 5px",
    borderWidth: 1,
    borderColor: '#333',
    backgroundColor: '#333',
  },
  tableCell: {
    padding: "5px 0px 5px 5px",
    borderWidth: 1,
    borderColor: '#333',
    backgroundColor: '#FFF',
  },
  cellText: {
    fontSize: 8,
    color: '#000',
    textAlign: 'left',
  },
  line: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#D3D3D3',
    backgroundColor: '#D3D3D3',
  }
});