import { Document, Page, Text, View } from '@react-pdf/renderer'
import { NumberUtils } from 'fwork-jsts-common/src'
import React from 'react'
import { stylesPdfDocument } from './styles'

interface ITableHeadCell {
  fieldName: string,
  flex: number | undefined
}

const tableHeadCells: ITableHeadCell[] = [
  { fieldName: "TÍTULO", flex: 3 },
  { fieldName: "QTD. BILHETES", flex: 1 },
  { fieldName: "QTD. NÚMEROS VENDIDOS", flex: 1 },
  { fieldName: "TOTAL", flex: 1 },
  { fieldName: "TOTAL COMISSÕES", flex: 1 }
]

export const TableHeader = tableHeadCells.map((cell, index) => (
  <View key={index} style={{ ...stylesPdfDocument.tableCellHeader, flex: cell.flex }}>
    <Text style={stylesPdfDocument.headerText}>{cell.fieldName}</Text>
  </View>
))

export const pdfAmountDocument = (props: {
  data: any
}) => {
  return <Document>
    <Page size="A4" orientation='landscape' style={stylesPdfDocument.page}>
      <View style={stylesPdfDocument.section}>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ fontSize: 10, paddingVertical: 2, paddingRight: 10 }}>
            Data: {new Date().toLocaleDateString('pt-BR')}
          </Text>
        </View>

        <View><Text style={stylesPdfDocument.title}>MOVIMENTAÇÃO DE BILHETES - TOTAL / TOTAL COMISSÕES</Text></View>

        <View style={stylesPdfDocument.table}>
          <View style={stylesPdfDocument.tableRow}>
            {TableHeader}
          </View>

          <View
            key={1}
            style={{
              ...stylesPdfDocument.tableRow,
            }}
          >
            {/* TITLE */}
            <View
              style={{
                ...stylesPdfDocument.tableCell,
                ...stylesPdfDocument.line,
                flex: 3,
              }}
            >
              <Text style={stylesPdfDocument.cellText}>
                {props.data[0]?.title}
              </Text>
            </View>

            {/* NUMBER OF TICKETS */}
            <View
              style={{
                ...stylesPdfDocument.tableCell,
                ...stylesPdfDocument.line,
                flex: 1,
              }}
            >
              <Text style={stylesPdfDocument.cellText}>
                {props.data[0]?.totalTicketsCount}
              </Text>
            </View>
            
            {/* NUMBER OF NUMBERS SOLD */}
            <View
              style={{
                ...stylesPdfDocument.tableCell,
                ...stylesPdfDocument.line,
                flex: 1,
              }}
            >
              <Text style={stylesPdfDocument.cellText}>
                {props.data[0]?.totalNumbersCount}
              </Text>
            </View>

            {/* TOTAL TICKET PRICE */}
            <View
              style={{
                ...stylesPdfDocument.tableCell,
                ...stylesPdfDocument.line,
                flex: 1,
              }}
            >
              <Text style={stylesPdfDocument.cellText}>
                {NumberUtils.getNumberStr(props.data[0]?.totalTicketsValue)}
              </Text>
            </View>

            {/* TOTAL VALUE OF TICKET COMMISSIONS */}
            <View
              style={{
                ...stylesPdfDocument.tableCell,
                ...stylesPdfDocument.line,
                flex: 1,
              }}
            >
              <Text style={stylesPdfDocument.cellText}>
                {NumberUtils.getNumberStr(props.data[0]?.totalCommissionsValue)}
              </Text>
            </View>

          </View>
        </View>
      </View>
    </Page>
  </Document>
}