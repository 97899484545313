import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import React from 'react'
import moment from 'moment'
import { stylesPdfDocument } from './styles'
import { ITicket, PaymentTypes } from 'rifa.lib.common.ts/models'
import { NumberUtils } from 'fwork-jsts-common/src'
import { PaymentTransactionsStatusDescriptions } from '@sl7/payment.lib.common.ts/models'
import { SearchFilters } from '..'

interface ITableHeadCell {
  fieldName: string,
  flex: number | undefined
}

const tableHeadCells: ITableHeadCell[] = [
  { fieldName: "TÍTULO", flex: 1 },
  { fieldName: "NOME", flex: 1 },
  { fieldName: "TELEFONE", flex: 1 },
  { fieldName: "DATA", flex: 1 },
  { fieldName: "VALOR BRUTO", flex: 1 },
  { fieldName: "TAXA %", flex: 1 },
  { fieldName: "VALOR LÍQ.", flex: 1 },
  { fieldName: "TIPO PAGTO", flex: 1 },
  { fieldName: "STATUS PAGTO", flex: 1 },
  { fieldName: "AFILIADO", flex: 1 },
]

export const TableHeader = tableHeadCells.map((cell, index) => (
  <View key={index} style={{ ...stylesPdfDocument.tableCellHeader, flex: cell.flex }}>
    <Text style={stylesPdfDocument.headerText}>{cell.fieldName}</Text>
  </View>
))

export const pdfMovementDocument = (props: {
  data: ITicket[]
  filters: SearchFilters
}) => {
  return <Document>
    <Page size="A4" orientation='landscape' style={stylesPdfDocument.page}>
      <View style={stylesPdfDocument.section}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ fontSize: 10, paddingVertical: 2, paddingRight: 10 }}>
            Data Inicial: {props.filters?.initialDate && new Date(props.filters?.initialDate).toLocaleDateString('pt-BR')} 
          </Text>
          <Text style={{ fontSize: 10, paddingVertical: 2 }}>
            Data Final: {props.filters?.finalDate && new Date(props.filters?.finalDate).toLocaleDateString('pt-BR')}
          </Text>
        </View>

        {props.filters?.searchCustomer ? <>
          <View>
            <Text style={{ fontSize: 10, paddingVertical: 2 }}>
              CPF/Telefone/Email : {props.filters?.searchCustomer}
            </Text>
          </View>
        </> : <></>}

        {props.filters?.searchTicketNumber ? <>
          <View>
            <Text style={{ fontSize: 10, paddingVertical: 2 }}>
              Número escolhido pelo cliente: {props.filters?.searchTicketNumber}
            </Text>
          </View>
        </> : <></>}

        {props.filters?.searchPrizeDraw ? <>
          <View>
            <Text style={{ fontSize: 10, paddingVertical: 2 }}>
              Sorteio: {props.filters?.searchPrizeDraw}
            </Text>
          </View>
        </> : <></>}

        {props.filters?.paymentStatus ? <>
          <View>
            <Text style={{ fontSize: 10, paddingVertical: 2 }}>
              Status do Pagamento: {PaymentTransactionsStatusDescriptions.filter(s => s.value === (props.filters?.paymentStatus ? props.filters?.paymentStatus : 0))[0]?.description}

            </Text>
          </View>
        </> : <></>}

        <View><Text style={stylesPdfDocument.header}>MOVIMENTAÇÃO DE BILHETES</Text></View>

        <View style={stylesPdfDocument.table}>
          <View style={stylesPdfDocument.tableRow}>
            {TableHeader}
          </View>

          {props.data?.map((item: ITicket, index: any) => {
            const value = item.value || 0;
            const entranceFee = item.payments?.length ? item.payments[0]?.pixEntranceFee || 0 : 0;
            const entranceFeeValue = (entranceFee * value) / 100;
            const netValue = value - entranceFeeValue;
            const paymentType = item.payments?.length ? item.payments[0]?.type : undefined;

            return (
              <View
                key={index}
                style={{
                  ...stylesPdfDocument.tableRow,
                  ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                }}
              >
                {/* TITLE */}
                <View
                  style={{
                    ...stylesPdfDocument.tableCell,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                    flex: 1,
                  }}
                >
                  <Text style={stylesPdfDocument.cellText}>
                    {item.prizeDraw?.title}
                  </Text>
                </View>

                {/* NAME */}
                <View
                  style={{
                    ...stylesPdfDocument.tableCell,
                    ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                    flex: 1,
                  }}
                >
                  <Text style={stylesPdfDocument.cellText}>
                    {item.customer?.name}
                  </Text>
                </View>

                {/* PHONE */}
                <View
                  style={{
                    ...stylesPdfDocument.tableCell,
                    ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                    flex: 1,
                  }}
                >
                  <Text style={stylesPdfDocument.cellText}>
                    {item.customer?.phoneNumber}
                  </Text>
                </View>

                {/* DATE */}
                <View
                  style={{
                    ...stylesPdfDocument.tableCell,
                    flex: 1,
                    ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                  }}
                >
                  <Text style={stylesPdfDocument.cellText}>
                    {`${moment(item.dateTime as string).format('DD/MM/YYYY HH:mm')}`}
                  </Text>
                </View>

                {/* TOTAL VALUE */}
                <View
                  style={{
                    ...stylesPdfDocument.tableCell,
                    ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                    flex: 1,
                  }}
                >
                  <Text style={stylesPdfDocument.cellText}>
                    {NumberUtils.getNumberStr(value)}
                  </Text>
                </View>

                {/* RATE */}
                <View
                  style={{
                    ...stylesPdfDocument.tableCell,
                    ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                    flex: 1,
                  }}
                >
                  <Text style={stylesPdfDocument.cellText}>
                    {entranceFeeValue}
                  </Text>
                </View>

                {/* NETVALUE */}
                <View
                  style={{
                    ...stylesPdfDocument.tableCell,
                    ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                    flex: 1,
                  }}
                >
                  <Text style={stylesPdfDocument.cellText}>
                    {NumberUtils.getNumberStr(netValue)}
                  </Text>
                </View>

                {/* PAYMENT TYPE */}
                <View
                  style={{
                    ...stylesPdfDocument.tableCell,
                    ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                    flex: 1,
                  }}
                >
                  <Text style={stylesPdfDocument.cellText}>
                    {paymentType == PaymentTypes.pix ? 'Pix' : 'Cambista'}
                  </Text>
                </View>

                {/* PAYMENT STATUS */}
                <View
                  style={{
                    ...stylesPdfDocument.tableCell,
                    ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                    flex: 1,
                  }}
                >
                  <Text style={stylesPdfDocument.cellText}>
                    {PaymentTransactionsStatusDescriptions.filter(s => s.value === (item.payments?.length ? item.payments[0].status : 0))[0]?.description}
                  </Text>
                </View>

                {/* AFFILIATE */}
                <View
                  style={{
                    ...stylesPdfDocument.tableCell,
                    ...(index % 2 === 0 ? stylesPdfDocument.oddLine : stylesPdfDocument.evenLine),
                    flex: 1,
                  }}
                >
                  <Text style={stylesPdfDocument.cellText}>
                    {item.affiliateCommissions?.length ? item.affiliateCommissions[0].affiliate?.name : ''}
                  </Text>
                </View>
              </View>
            );
          })}

          {/* FOOTER */}
          <View style={{ ...stylesPdfDocument.footer, flexDirection: 'row' }}>
            <View style={{ ...stylesPdfDocument.tableCell, ...stylesPdfDocument.footer, }} />
            <View style={{ ...stylesPdfDocument.tableCell, ...stylesPdfDocument.footer, }} />
            <View style={{ ...stylesPdfDocument.tableCell, ...stylesPdfDocument.footer, }} />
            <View style={{ ...stylesPdfDocument.tableCell, ...stylesPdfDocument.footer, }} />
            <View style={{ ...stylesPdfDocument.tableCell, ...stylesPdfDocument.footer, }}>
              <Text style={{ ...stylesPdfDocument.cellText, ...stylesPdfDocument.footer, fontWeight: 'bold', fontSize: 10 }}>
                {NumberUtils.getNumberStr(props.data.reduce((accumulator, item) => accumulator + item.value, 0))}
              </Text>
            </View>
            <View style={{ ...stylesPdfDocument.tableCell, ...stylesPdfDocument.footer, }}>
            </View>
            <View style={{ ...stylesPdfDocument.tableCell, ...stylesPdfDocument.footer, }}>
              <Text style={{ ...stylesPdfDocument.cellText, ...stylesPdfDocument.footer, fontWeight: 'bold', fontSize: 10 }}>
                {NumberUtils.getNumberStr(props.data.reduce((accumulator, item) => {
                  const value = item.value || 0;
                  const entranceFee = item.payments?.length ? item.payments[0]?.pixEntranceFee || 0 : 0;
                  const entranceFeeValue = (entranceFee * value) / 100;
                  const netValue = value - entranceFeeValue;

                  return accumulator + netValue;
                }, 0))}
              </Text>
            </View>
            <View style={{ ...stylesPdfDocument.tableCell, ...stylesPdfDocument.footer, }} />
            <View style={{ ...stylesPdfDocument.tableCell, ...stylesPdfDocument.footer, }} />
            <View style={{ ...stylesPdfDocument.tableCell, ...stylesPdfDocument.footer, }} />
          </View>
        </View>
      </View>
    </Page>
  </Document>
}