import { configureStore } from '@reduxjs/toolkit'
import { getRootState } from 'fwork.react.apptemplate'
import { useDispatch } from 'react-redux'
// PRA EXECUTAR DISPATCH COM FUNCOES PRECISA DO THUNK
// SE NAO TIVER O THUNNK GERA O ERRO
// Uncaught (in promise) Error: Actions must be plain objects. Instead, the actual type was: 'function'. You may need to add middleware to your store setup to handle dispatching other values, such as 'redux-thunk' to handle dispatching functions
import thunk from 'redux-thunk'

import { IUser } from 'rifa.lib.common.ts/models'
import dotEnvProductionReducer, { dotEnvProductionStateLoadFromApi } from './reducers/dotenvProduction.slice'
import loadingBarReducer from './reducers/loadingBar.slice'
import prizeDrawsReducer from './reducers/prizeDraws.slice'
import settingsReducer from './reducers/settings.slice'
import ticketsReducer from './reducers/tickets.slice'
import usersReducer from './reducers/users.slice'

export const store = configureStore({
  reducer: {
    ...getRootState<IUser>(),
    dotEnvProductionState: dotEnvProductionReducer,
    prizeDrawsState: prizeDrawsReducer,
    settingsState: settingsReducer,
    ticketsState: ticketsReducer,
    usersState: usersReducer,
    loadingBarState: loadingBarReducer
  },
  middleware: [thunk],
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

store.dispatch<any>(dotEnvProductionStateLoadFromApi())