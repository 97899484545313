import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { pdf } from '@react-pdf/renderer';
import React from 'react';
import { MdLocalPrintshop } from 'react-icons/md';
import { SearchFilters } from '../../components/prizeDrawsMovement';

export const handlePrintDocument = async ({
  data,
  document,
  filters
}: {
  data: any;
  document: React.ElementType;
  filters?: SearchFilters;
}) => {
  // Create an instance of the passed component with props
  const documentInstance = React.createElement(document, { data, filters })

  // Generate the PDF as a Blob
  const blob = await pdf(documentInstance).toBlob()

  // Create a temporary URL and open in a new tab
  const url = URL.createObjectURL(blob)
  const printWindow = window.open(url, "_blank")

  if (printWindow) {
    printWindow.onload = () => {
      printWindow.print()
      printWindow.onafterprint = () => printWindow.close()
    }
  }
}

export const PDFPrintComponent = (props: {
  data: any
  document: React.ElementType
  filters?: SearchFilters
  toolTipTitle?: String
}) => {

  return (
    <Tooltip placement="bottom-end" title="Imprimir">
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <IconButton onClick={() => {
          handlePrintDocument({
            data: props.data,
            document: props.document,
            filters: props.filters
          })
        }}>
          <MdLocalPrintshop color="#606060" size={25} />
        </IconButton>
      </div>
    </Tooltip>
  )
}
