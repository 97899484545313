import { Button, IconButton, styled } from "@mui/material"
import { ImageOrInitLettersComponent } from "fwork.react.apptemplate/src/components/image"
import React from "react"
import { IStyle } from "rifa.lib.common.ts/models"
import MoreVertIcon from '@mui/icons-material/MoreVert'

const ResultSetItemRootStyled = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  gap: 10,
  color: 'grey',
  borderRadius: 0,
  textTransform: 'none',
  padding: 10,
  width: '100%'
}))

export const ResultSetItemComponent = function <T>(props: {
  showImg?: boolean
  label: React.ReactNode
  obj: T
  imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
  onClick?: (obj: T) => void
  onClickMoreOptions?: (event: React.MouseEvent) => void
  style?: IStyle
}) {
  return <ResultSetItemRootStyled
    onClick={() => {
      if (props.onClick)
        props.onClick(props.obj)
    }} >
    {
      props.showImg ? (
        <ImageOrInitLettersComponent
          src={props.imgProps?.src}
          {...props.imgProps}
          style={{
            width: 48,
            height: 48,
            borderRadius: '100%',
            objectFit: 'cover',
            // color: props.style?.textColor,
            color: 'white',
            background: props.style?.color,

            ...props.imgProps?.style
          }}
          initLettersText={props.label?.toString()}
          onlyFirstAndLastWord={true}
        />
      ) : <></>
    }
    <label>{props.label}</label>

    {props.onClickMoreOptions ? <IconButton style={{ marginLeft: 'auto' }} onClick={(event) => {
      event.stopPropagation()
      if (props.onClickMoreOptions)
        props.onClickMoreOptions(event)
    }}>
      <MoreVertIcon />
    </IconButton> : <></>}

  </ResultSetItemRootStyled>
}