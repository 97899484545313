import { ITicket, ITicketPayment } from 'rifa.lib.common.ts/models'
import { AxiosRequestConfig, AxiosStatic } from 'axios'
import { IApiClientResult } from 'fwork-jsts-common/src/apiClient'
import { ApiResponseGetData, ApiResponseGetListData } from 'fwork-jsts-common/src/api'
import { ApiClientUtils, BaseApiClient } from 'fwork-jsts-common/src/apiClient'
import { FilterQuery } from 'mongoose'
import { store } from '../redux/store'
import { ApiRoutesNames } from 'rifa.lib.common.ts/api/routes'

export type TicketsApiClient_getTicketsToRefund_result = {
  payload?: {
    ticketsDuplicatedPayments?: Partial<ITicket>[],
    ticketsCanceledAndPayed?: Partial<ITicket>[],
  }
}

export class TicketsApiClient extends BaseApiClient<ITicket, any, FilterQuery<ITicket>, ITicket, ITicket> {
  constructor(axios: AxiosStatic, apiUrl?: string | undefined) {
    const dotEnvProductionState = store.getState().dotEnvProductionState
    const baseUrl = apiUrl || `${dotEnvProductionState.payload?.APIURL}${ApiRoutesNames.tickets}`
    super({
      apiUrl: baseUrl,
      axios
    })
  }

  async getPayments(args?: {
    id: string,
    config?: AxiosRequestConfig<ITicket> | undefined
  }): Promise<IApiClientResult<ApiResponseGetListData<ITicketPayment>>> {
    return ApiClientUtils.get<any, FilterQuery<ITicket>>({
      axios: this.axios,
      apiUrl: `${this.apiUrl}/${args?.id}/payments`,
      config: args?.config
    })
  }

  async getWinners(args?: {
    where?: FilterQuery<ITicket>,
    select?: any,
    params?: any,
    config?: AxiosRequestConfig<ITicket> | undefined
  }): Promise<IApiClientResult<ApiResponseGetListData<ITicket>>> {
    return ApiClientUtils.get({
      axios: this.axios,
      apiUrl: `${this.apiUrl}/winners`,
      where: args?.where,
      select: args?.select,
      params: args?.params,
      config: args?.config
    })
  }

  async getTicketsToRefund(args: {
    config?: AxiosRequestConfig | undefined
  }): Promise<IApiClientResult<ApiResponseGetData<TicketsApiClient_getTicketsToRefund_result>>> {
    const result: IApiClientResult<ApiResponseGetData<TicketsApiClient_getTicketsToRefund_result>> = {}
    result.data = {}

    try {
      const response = await this.axios?.get<ApiResponseGetData<TicketsApiClient_getTicketsToRefund_result>>(`${this.apiUrl}/torefund`, {
        params: {
          ...args.config?.params
        },
        ...args.config
      })

      if (response?.status === 200) {
        result.data = response.data
        result.success = true
      }
      else {
        result.success = false
      }
    } catch (error) {
      result.success = false
      result.msg = ApiClientUtils.getErrorMessage(error)
    }

    return result
  }
}