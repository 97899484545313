import { Menu, MenuItem } from '@mui/material'
import { ApiClientUtils } from 'fwork-jsts-common/src/apiClient'
import { FilterQuery } from 'mongoose'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { MdLocalPrintshop } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IPrizeDraw } from 'rifa.lib.common.ts/models'
import axios from '../../../apiClient/axios'
import { PrizeDrawsApiClient } from '../../../apiClient/prizeDraws'
import { RoutesNames } from '../../../common/globals'
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { RootState } from '../../../redux/store'
import { DEFAULTGAP } from '../../globals'
import { handlePrintDocument } from '../../utils/pdf/index'
import { HeaderComponent } from '../miscelaneous/header'
import { ResultSetItemComponent } from '../miscelaneous/resultSetItem'
import { pdfAmountDocument } from './pdf/document'

export const PrizeDrawsComponent = (props: {
  data: IPrizeDraw[] | undefined,
}) => {
  const navigate = useNavigate()
  const [localPrizeDraws, setLocalPrizeDraws] = useState<IPrizeDraw[] | undefined>()
  const { enqueueSnackbar } = useSnackbar()
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  const [dataContext, setDataContext] = useState<any>()
  const [contextMenuPopUp, setContextMenuPopUp] = useState<{
    mouseX: number
    mouseY: number
  } | null>(null)

  // Exibe o menu de contexto ao clicar com o botão direito do mouse
  // Controla a posição do conteúdo do menu de contexto
  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    setContextMenuPopUp({
      mouseX: event.clientX - 2, // Posição horizontal do menu
      mouseY: event.clientY - 32, // Posição vertical do menu
    })
  }

  const handleCloseContextMenu = () => setContextMenuPopUp(null)

  useEffect(() => {
    if (!localPrizeDraws?.length)
      loadData()
  }, [])

  const loadData = async () => {
    try {
      let where: FilterQuery<IPrizeDraw> | undefined

      const response = await new PrizeDrawsApiClient(axios).get({
        where
      })

      if (response.success) {
        setLocalPrizeDraws(response.data?.payload)
      }

    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  return <>
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
      title='Sorteios'
      onAddClick={() => {
        navigate(RoutesNames.adminPrizedraw.replace(':id', 'new'))
      }}
      onRefreshClick={loadData}
    />

    {localPrizeDraws?.map(prizeDraw => <ResultSetItemComponent
      key={prizeDraw._id}
      showImg={true}
      imgProps={{
        src: `${dotEnvProductionState.payload?.APIURL}/img/${prizeDraw.imgUrl?.toString()}`,
        style: {
          borderRadius: 5
        }
      }}
      label={`${prizeDraw.title}`}
      obj={prizeDraw}
      onClick={(obj) => {
        navigate(RoutesNames.adminPrizedraw.replace(':id', prizeDraw._id))
      }}
      onClickMoreOptions={async (event) => {
        handleContextMenu(event)
        setDataContext((await axios.get(`${dotEnvProductionState.payload?.APIURL}/prizedraws/${prizeDraw._id}/amount`)).data)
      }}
    />)}

    {/* Menu para exibir as opções de ação do sorteio */}
    <Menu
      open={contextMenuPopUp !== null}
      onClose={handleCloseContextMenu}
      anchorReference="anchorPosition"
      anchorPosition={contextMenuPopUp !== null ? { top: contextMenuPopUp.mouseY, left: contextMenuPopUp.mouseX } : undefined}
    >
      <MenuItem style={{ padding: 8 }} onClick={() => {
        handlePrintDocument({
          data: dataContext,
          document: pdfAmountDocument
        })

        handleCloseContextMenu()
      }} >
        <MdLocalPrintshop color="#606060" size={25} />
        <div style={{ paddingLeft: 8 }}>Gerar Movimentação</div>
      </MenuItem>
    </Menu>

  </>
}