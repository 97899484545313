import React, { useEffect, useState } from "react"
import moment from "moment"
import { IPrizeDraw, IPrizeDrawAwardedNumber, ITicket } from "rifa.lib.common.ts/models"
import { PrizeDrawAdditionalInfoStyled } from "./index.styled"
import { NumberUtils } from "fwork-jsts-common/src"
import { styled } from "@mui/material"

interface AwardedNumbersComponentProps {
  awardedNumbers: IPrizeDrawAwardedNumber[],
  winners?: ITicket[]
}

const AwardedNumberWinnerBoxStyled = styled('span')({
  display: 'flex',
  alignItems: 'center',
  textAlign:'center',
  padding: '5px 10px',
  backgroundColor: '#fff',
  border: `2px solid #3CB371`,
  borderRadius: '5px',
  color: '#3CB371'
})

const AwardedNumberEnabledBoxStyled = styled('span')({
  display: 'flex',
  alignItems: 'center',
  textAlign:'center',
  padding: '5px 10px',
  backgroundColor: '#3CB371',
  border: `2px solid #3CB371`,
  borderRadius: '5px',
  color: '#FFF'
})

const AwardedNumberFutureBoxStyled = styled('span')({
  display: 'flex',
  alignItems: 'center',
  textAlign:'center',
  padding: '5px 10px',
  backgroundColor: '#FFF',
  border: `2px solid #A9A9A9`,
  borderRadius: '5px',
  color: '#A9A9A9'
})

export const AwardedNumbersComponent = ({ awardedNumbers, winners }: AwardedNumbersComponentProps) => {
  // NÚMEROS INDISPONÍVEIS PARA O FINAL DA LISTA
  const sortedAwardedNumbers = awardedNumbers.sort((a, b) => {
    const isInitiatedA = moment().unix() >= a.validFromDateUnix
    const isInitiatedB = moment().unix() >= b.validFromDateUnix
    return isInitiatedA == isInitiatedB ? 0 : isInitiatedA ? -1 : 1
  })

  let ticketWinnersAwardedNumbers = winners?.flatMap(ticket => ticket.awardedNumbers?.map(award => award.number))

  return (
    <PrizeDrawAdditionalInfoStyled style={{ zIndex: 1, padding: 15, }}>
      <h3 style={{ margin: 0 }}>COTAS PREMIADAS</h3>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '25px', marginTop: 20 }}>
        {sortedAwardedNumbers?.map((awardedNumber: IPrizeDrawAwardedNumber, idx) => {

          const isWinner = ticketWinnersAwardedNumbers?.indexOf(awardedNumber.number) != -1
          const isInitiated = moment().unix() >= awardedNumber.validFromDateUnix
          const winnerName = winners?.filter(t => t.awardedNumbers?.some(an => an.number == awardedNumber.number))[0]?.customer?.name

          return (
            <div key={idx} >
              <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Prêmio - R$ {NumberUtils.getNumberStr(awardedNumber.awardValue)}
              </div>

              <div style={{ display: 'flex', gap: '10px', alignItems: 'stretch', }}>
                {isWinner ? <AwardedNumberWinnerBoxStyled>{winnerName ? `Vencedor ${winnerName}` : 'Carregando...'}</AwardedNumberWinnerBoxStyled> :
                  isInitiated ? <AwardedNumberEnabledBoxStyled>Disponível</AwardedNumberEnabledBoxStyled> :
                    <AwardedNumberFutureBoxStyled>Em breve</AwardedNumberFutureBoxStyled>}
                {isWinner ? <AwardedNumberWinnerBoxStyled>Cota {awardedNumber.number.toString()}</AwardedNumberWinnerBoxStyled> :
                  isInitiated ? <AwardedNumberEnabledBoxStyled>Cota {awardedNumber.number.toString()}</AwardedNumberEnabledBoxStyled> :
                    <AwardedNumberFutureBoxStyled>Cota {awardedNumber.number.toString()}</AwardedNumberFutureBoxStyled>}
              </div>
            </div>
          )
        })}
      </div>
    </PrizeDrawAdditionalInfoStyled>
  )
}