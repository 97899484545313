export const ApiRoutesNames = {
  img: '/img',
  authentication: '/auth', // POST(*)
  customers: '/customers',
  prizedrawProviders: '/prizedraw-providers',
  settings: '/settings', // POST, GET(*), PUT
  users: '/users',
  transactions_sl7payWebHook_update: '/transactions/sl7paywebhook-update',
  affiliate_commissions: '/affiliate-commissions',
  system: '/system', // GET

  prizedraws: '/prizedraws', // POST, GET(*), PUT
  prizeDrawsInitialize: '/prizedraws/:id/initialize', // POST
  prizeDrawsGetAvailableQuotes: '/prizedraws/:id/getavailablequotes/:ticketid/:count', // GET(*)
  prizeDrawsUnavailableNumbers: '/prizedraws/:id/unavailable-numbers', // GET(*)
  prizeDrawsUnavailableNumbersLength: '/prizedraws/:id/unavailable-numbers-length', // GET(*)
  prizeDrawsAvailableNumbersCount: '/prizedraws/:id/available-numbers-count', // GET(*)
  prizedrawsCustomerRankingByQuoteCount: '/prizedraws/:id/customer-ranking-by-quotecount', // GET(*)
  prizedrawsAmount: '/prizedraws/:id/amount',

  tickets: '/tickets',  // POST(*), GET(*), PUT
  ticketsPayments: '/tickets/:id/payments', // GET(*)
  ticketsRenewNumbersConfirm: '/tickets/renew-confirm/:token', // POST(*)
  ticketsRenewNumbers: '/tickets/renew-req/:id', // POST
  ticketsWinners: '/tickets/winners', // GET(*)
  ticketsWinnersAwardedNumbers: '/tickets/winners-awarded-numbers', // GET(*)
  ticketsToRefund: '/tickets/torefund', // GET
}

// * SKIP AUTH