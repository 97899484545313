import { Button } from '@mui/material'
import { ApiClientUtils, NumberUtils, StringUtils } from 'fwork-jsts-common/src'
import { TableBaseComponent } from 'fwork-react-mui-ext/src/table/base'
import { StyledTableCell, StyledTableRow, TableComponentSetCurrPageProps } from 'fwork-react-mui-ext/src/table/common'
import { FormItemStyled, FormStyled } from 'fwork.react.apptemplate'
import { ImageOrInitLettersComponent } from 'fwork.react.apptemplate/src/components/image'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ITicket, ITicketPayment, PaymentStatus, PaymentStatusDescriptions } from 'rifa.lib.common.ts/models'
import axios from '../../../apiClient/axios'
import { TicketsApiClient } from '../../../apiClient/tickets'
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { toggleLoading } from '../../../redux/reducers/loadingBar.slice'
import { RootState } from '../../../redux/store'
import { DEFAULTGAP } from '../../globals'
import { HeaderComponent } from '../miscelaneous/header'
import styled from '@emotion/styled'
import { ConfirmDialogComponent } from '../../../common/components/dialogComponent/confirm'

export enum TicketExtRefundType {
  canceledAndPayed,
  duplicatedPayments,
}

export interface ITicketExt extends ITicket {
  refundType: TicketExtRefundType
}

const StyledTableTitleCell = styled(StyledTableCell)(({ theme }) => ({
  fontWeight: 'bold',
  whiteSpace: 'nowrap'
}))

export const PaymentStatusComponent = (options: {
  payment: ITicketPayment
}) => {
  const { payment, ...rest } = options

  const pOptions = PaymentStatusDescriptions.filter(d => d.value == payment.status)[0]

  return <div style={{ width: 100, textAlign: 'center', background: pOptions.color, borderRadius: 5, color: 'white', padding: '3px 8px' }}>{pOptions.description.toUpperCase()}</div>
}

export const TicketsToRefundComponent = () => {
  const tableComponentSetCurrPageRef = React.useRef<TableComponentSetCurrPageProps>(() => { });
  const tableComponentSetCurrPage = tableComponentSetCurrPageRef.current

  const dispatch = useDispatch<any>()
  const [data, setData] = useState<Partial<ITicketExt>[]>()
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [dataToEdit, setDataToEdit] = useState<{
    ticket: any,
    payment: any
  }>()

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    try {
      dispatch(toggleLoading())

      const response = await new TicketsApiClient(axios).getTicketsToRefund({})

      if (response.success) {
        setData((response.data?.payload?.ticketsCanceledAndPayed?.map(t => ({ ...t, refundType: TicketExtRefundType.canceledAndPayed } as ITicketExt)) ?? [])
          .concat(response.data?.payload?.ticketsDuplicatedPayments?.map(t => ({ ...t, refundType: TicketExtRefundType.duplicatedPayments } as ITicketExt)) ?? []) ?? [])
      } else {
        enqueueSnackbar('Erro ao consultar', { variant: 'error' })
      }

    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    } finally {
      dispatch(toggleLoading())
    }
  }

  return <>
    <ConfirmDialogComponent
      title="Reembolso"
      text="Deseja realmente devolver este valor?"
      open={openDialog}
      onConfirm={async () => {
        try {
          dispatch(toggleLoading())
          setOpenDialog(false)

          if (dataToEdit)
            dataToEdit.payment.status = PaymentStatus.refund

          const response = await new TicketsApiClient(axios).put({
            data: dataToEdit?.ticket
          })

          if (response.success) {
            setData(old => {
              return old?.map(t => t._id == dataToEdit?.ticket._id ? dataToEdit?.ticket : t)
            })
            enqueueSnackbar("Operação realizada com sucesso", { variant: 'success' })
          } else {
            enqueueSnackbar('Erro ao atualizar', { variant: 'error' })
          }
        } catch (error) {
          enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
        } finally {
          dispatch(toggleLoading())

          setDataToEdit({
            ticket: null,
            payment: null
          })
        }

      }}
      onCancel={() => {
        setOpenDialog(false)
      }}
      onClose={() => setOpenDialog(false)}
    />
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
      title='Valores a devolver'
      onRefreshClick={async () => {
        await load()
      }}
    />
    <FormStyled>
      <FormItemStyled>

      </FormItemStyled>
    </FormStyled>

    <TableBaseComponent
      wrapperProps={{ style: { padding: DEFAULTGAP, maxWidth: 'calc(100% - 10px)' } }}
      setCurrPageRef={tableComponentSetCurrPageRef}
      header={<StyledTableRow>
        <StyledTableTitleCell></StyledTableTitleCell>
        <StyledTableTitleCell>Nome do cliente</StyledTableTitleCell>
        <StyledTableTitleCell>CPF</StyledTableTitleCell>
        <StyledTableTitleCell>Telefone</StyledTableTitleCell>
        <StyledTableTitleCell>Email</StyledTableTitleCell>
        <StyledTableTitleCell>Id do ticket</StyledTableTitleCell>
        <StyledTableTitleCell>Data</StyledTableTitleCell>
        <StyledTableTitleCell>Valor do ticket</StyledTableTitleCell>
        {/* <StyledTableTitleCell>Tipo</StyledTableTitleCell> */}

        <StyledTableTitleCell>Dados do pagamento</StyledTableTitleCell>
      </StyledTableRow>}
      bodyList={data ?? []}
      bodyRowBuilder={(ticket: ITicketExt) => {
        return <StyledTableRow>
          <StyledTableCell><ImageOrInitLettersComponent
            src={`${dotEnvProductionState.payload?.APIURL}/img/${ticket.customer?.imgProfileUrl}`}
            initLettersText={ticket.customer?.name}
            onlyFirstAndLastWord={true}
            style={{
              width: 45,
              height: 45,
              objectFit: 'cover',
              borderRadius: '100%',
              color: 'white',
              fontSize: 20
            }}
          /></StyledTableCell>
          <StyledTableCell>{ticket.customer?.name}</StyledTableCell>
          <StyledTableCell>{ticket.customer?.cpf}</StyledTableCell>
          <StyledTableCell>{ticket.customer?.phoneNumber}</StyledTableCell>
          <StyledTableCell>{ticket.customer?.email}</StyledTableCell>
          <StyledTableCell><>
            <div>{ticket._id}</div>
            {ticket.canceled == true && <div style={{ marginTop: 5, background: 'orangered', borderRadius: 5, color: 'white', padding: '3px 8px', width: 100, textAlign: 'center' }}>CANCELADO</div>}
          </></StyledTableCell>
          <StyledTableCell>{ticket.dateTime}</StyledTableCell>
          <StyledTableCell>{`R$ ${NumberUtils.getNumberStr(ticket.value)}`}</StyledTableCell>
          {/* <StyledTableCell>{ticket.refundType}</StyledTableCell> */}


          <StyledTableCell>
            {ticket.payments?.map((p, idx) => <div style={{ marginTop: idx > 0 ? 10 : 0, display: 'flex', alignItems: 'center', gap: 10 }}>
              <div>{p.sl7PayId}</div>
              <div style={{ whiteSpace: 'nowrap', width: 100 }}>{`R$ ${NumberUtils.getNumberStr(p.value)}`}</div>
              <div><PaymentStatusComponent payment={p} /></div>
              <div style={{width: 100}}>
                {((idx > 0 || ticket.refundType == TicketExtRefundType.canceledAndPayed) && p.status != PaymentStatus.refund) &&
                  <Button onClick={async () => {
                    setDataToEdit({
                      ticket: ticket,
                      payment: p
                    })

                    setOpenDialog(true)
                  }}>Marcar devolvido</Button>}
              </div>
            </div>)}
          </StyledTableCell>
        </StyledTableRow>
      }}
    />
  </>
}